import * as React from 'react';
import { HeadFC, Link as GatsbyLink } from 'gatsby';

// Assets
import image from '../assets/images/404-img.svg';

// Components
import HeadMeta from '../components/Molecules/HeadMeta/HeadMeta';
import Section from 'fatcat-ui-library/components/Molecules/Section';
import { Heading } from 'fatcat-ui-library/components/Atoms/Heading';
import { Flex } from 'fatcat-ui-library/components/Atoms/Flex';
import { Paragraph } from 'fatcat-ui-library/components/Atoms/Paragraph';
import { Image } from 'fatcat-ui-library/components/Atoms/Image';
import { Button } from 'fatcat-ui-library/components/Atoms/Button';

const NotFoundPage = () => {
	return (
		<main>
			<Section>
				<Flex
					direction="column"
					padding={['t120', 'b80']}
					justifyContent="center"
					alignItems="center"
					tablet={[{ padding: ['b40'] }]}
				>
					<Image src={image} maxW="740px" maxH="290px" alt="404 image" mobile={[{ h: '133px' }]} />
					<Heading
						as="h2"
						margin={['b32', 't72']}
						textAlign="center"
						mobile={[{ margin: ['t40', 'b24'] }]}
					>
						Page not found.
					</Heading>
					<Paragraph
						margin={['b40']}
						textAlign="center"
						fontSize="s24"
						mobile={[{ margin: ['b32'] }]}
					>
						Sorry, we couldn’t find the page you’re
						<br />
						looking for.
					</Paragraph>
					<Button as={GatsbyLink} to="/">Back to home</Button>
				</Flex>
			</Section>
		</main>
	);
};

export default NotFoundPage;

export const Head: HeadFC = (props) => {
	return (
		<HeadMeta
			meta={{
				pageTitle: 'Enjoy good health | 404 page',
				metaDescription: '',
			}}
		/>
	);
};
